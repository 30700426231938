<template>
    <div id="CourseForm">
        <ElForm
            label-position="top"
            label-width="240px"
            ref="courseFormRef"
            :model="courseFormData"
            :rules="courseFormRules"
        >
            <h2 class="f29 f22-mb">お問い合わせ</h2>
            <p class="f14 f12-mb">
                必要事項をご入力の上、下記のフォームよりご送信下さい。折り返し、担当者よりご連絡させていただきます。
                <br />
                お問い合わせの内容によっては、回答にお時間をいただく場合がございます。あらかじめご了承下さい。
                <br />
                また、いただいたメールアドレスに誤りがある場合や、システム障害などにより、お返事出来ない場合がございます。
            </p>
            <h3 class="f14 f12-mb">必要事項をご記入ください。</h3>
            <section id="course-form-basic-info">
                <ElFormItem class="f14 f14-mb" label="お名前" prop="name">
                    <ElInput v-model="courseFormData.name"></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="メールアドレス"
                    prop="email"
                >
                    <ElInput v-model="courseFormData.email"></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="メールアドレス（確認用）"
                    prop="emailCheck"
                >
                    <ElInput v-model="courseFormData.emailCheck"></ElInput>
                </ElFormItem>
            </section>
            <h3 class="f14 f12-mb">
                下記のいずれか1つにチェックを入れてください。<span
                    class="is-required"
                    >※必須</span
                >
            </h3>
            <section id="course-form-select1">
                <ElFormItem class="f14 f14-mb" prop="select1">
                    <ElRadioGroup v-model="courseFormData.select1">
                        <ElRadio
                            label="真理学修（基本セミナー、神学科など）"
                        ></ElRadio>
                        <ElRadio label="「親理 総合理学修の理」"></ElRadio>
                        <ElRadio
                            label="「おつとめ親交式 純御修行の理」"
                        ></ElRadio>
                        <ElRadio label="「生命自覚宣誓の理」"></ElRadio>
                        <ElRadio label="「真御子宣誓」"></ElRadio>
                        <ElRadio label="ホームページについて"></ElRadio>
                        <ElRadio label="その他"></ElRadio>
                    </ElRadioGroup>
                </ElFormItem>
            </section>
            <h3 class="f14 f12-mb">
                お問い合わせ 目的<span class="is-required">※必須</span>
            </h3>
            <section id="course-form-select2">
                <ElFormItem class="f14 f14-mb" prop="select2">
                    <ElRadioGroup v-model="courseFormData.select2">
                        <ElRadio label="申し込み"></ElRadio>
                        <ElRadio label="ご相談"></ElRadio>
                        <ElRadio label="ご質問"></ElRadio>
                        <ElRadio label="その他"></ElRadio>
                    </ElRadioGroup>
                </ElFormItem>
            </section>
            <h3 class="f14 f12-mb">
                お問い合わせ内容をご自由にご記入ください。
            </h3>
            <p class="f12 f12-mb margin">
                例1）「親理
                総合理学修の理」に真参加させて頂きたいので、開催日時を教えてください。<br />
                例2）「生命自覚宣誓の理」を頂きたいので、詳しく教えてください。
            </p>
            <section id="course-form-question">
                <ElFormItem class="f14 f14-mb" prop="question">
                    <ElInput
                        type="textarea"
                        resize="none"
                        v-model="courseFormData.article"
                    ></ElInput>
                </ElFormItem>
            </section>
            <section id="course-form-submit">
                <button class="f29 f22-mb" @click.prevent="submitForm">
                    送 信
                </button>
            </section>
        </ElForm>
    </div>
</template>
<script>
export default {
    name: 'CourseForm',
}
</script>
<script setup>
import { reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { postCourse } from '@/service/api'
import { getCourseFormRules } from '@/utils/formRules'
const courseFormRef = ref(null)
const courseFormData = reactive({
    name: '',
    email: '',
    emailCheck: '',
    select1: '',
    select2: '',
    article: '',
})
const courseFormRules = getCourseFormRules(courseFormData)
const submitForm = () => {
    courseFormRef.value.validate((valid) => {
        if (valid) {
            let formData = {
                name: courseFormData.name,
                email: courseFormData.email,
                select1: courseFormData.select1,
                select2: courseFormData.select2,
                article: courseFormData.article,
            }
            postCourse(formData).then(() => {
                ElMessage({
                    message: 'ご記入いただいた情報は無事送信されました。',
                    type: 'success',
                    offset: 100,
                })

                setTimeout(() => {
                    courseFormData.name = ''
                    courseFormData.email = ''
                    courseFormData.emailCheck = ''
                    courseFormData.select1 = ''
                    courseFormData.select2 = ''
                    courseFormData.article = ''
                    location.reload()
                }, 2000)
            })
        }
    })
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';

#CourseForm {
    padding-top: 100px;
    color: $gray6;
    text-align: center;
    h2 {
        margin-bottom: 1em;
    }
    h3 {
        text-align: center;
        background: rgba($majorPurple, 0.1);
        .is-required {
            color: red;
        }
    }
    button {
        @include solidButtonStyle();
        margin: 0 auto;
        width: 200px;
    }
    .margin {
        margin-bottom: 2em;
    }

    /deep/ .el-radio {
        margin: 3px 0;
        .el-radio__input {
            align-self: start;
            margin-top: 7px;
        }
    }
    /deep/ .el-textarea__inner {
        height: 16em;
    }
    @media screen and (min-width: 768px) {
        h3 {
            margin: 5em 0 2em;
        }
        #course-form-submit {
            margin: 50px 0 80px;
        }
        section {
            padding: 0 Max(calc(50% - 240px), 60px);
        }
        #course-form-basic-info {
            padding: 0 Max(calc(50% - 180px), 60px);
        }
    }
    @media screen and(max-width:767px) {
        padding: 40px 0;
        h3 {
            margin: 3em 0 2em;
            line-height: 4em;
        }
        #course-form-submit {
            margin-top: 40px;
        }
    }
}
</style>
